import React, { useState, useEffect } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import moment from 'moment';

const NewEquipmentPickup = ({ customer, pickup_locations, dropoff_devices, csrf_token, ...props }) => {
  const { register, handleSubmit, watch, control, formState: { errors } } = useForm();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    rules: { required: true, minLength: 1},
    name: "equipment_pickup[equipment_pickup_tickets_attributes]", // unique name for your Field Array
  });
  const [formErrors, setFormErrors] = useState(null)
  const [pickupLocation, setPickupLocation] = useState(null)
  const [pickupData, setPickupData] = useState({})
  const [pickupDevices, setPickupDevices] = useState([])

  const onSubmit = data => {
    setFormErrors(null)

    fetch(`/crm/customers/${customer.id}/equipment_pickups`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token
      },
      body: JSON.stringify(data)
    }).then(response => {
      if (response.ok) {
        console.log("response", response)
        window.location = `/crm/customers/${customer.id}`
      } else {
        response.json().then(data => {
          console.log("data", data)
          setFormErrors(data.errors)
        })
      }
    })
  }

  const twoWeeksFromNow = () => {
    var to = new Date();
    to.setDate(to.getDate()+14)
    var day = ("0" + to.getDate()).slice(-2);
    var month = ("0" + (to.getMonth() + 1)).slice(-2);

    return to.getFullYear()+"-"+(month)+"-"+(day);
  }

  const fetchPickupDevices = async (locationId) => {
    const response = await fetch(`/crm/customers/${customer.id}/equipment_pickups/pickup_devices?location_id=${locationId}`)
    const data = await response.json()
    console.debug("Received PickupDevices data:", data)
    setPickupDevices(data)
  }

  useEffect(() => {
    if (pickupLocation) {
      fetchPickupDevices(pickupLocation)
    }
  }, [pickupLocation])

  useEffect(() => {
    console.log("pickupData", pickupData)
  }, [pickupData])

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header header-elements-inline">
            <h5 className="card-title">New Equipment Pickup</h5>
          </div>

          <div className="collapse show">
            <div className="card-body">
              {
                formErrors !== null ? (
                  <div className="alert alert-danger" role="alert">
                    {formErrors}
                  </div>
                ) : null
              }

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <label className="col-3 col-form-label">Location</label>
                  <div className="col-9">
                    <select {...register("equipment_pickup[pickup_location_id]")} className="form-control" onChange={e => setPickupLocation(e.target.value)}>
                      <option value="">Select a location</option>
                      {
                        pickup_locations.map((location, index) => {
                          return (
                            <option key={index} value={location.id}>{location.name}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3 col-form-label">Valid Until</label>
                  <div className="col-9">
                    <input type="date" defaultValue={twoWeeksFromNow()} {...register("equipment_pickup[valid_until]")} />
                  </div>
                </div>
                <div className="form-group">
                  <hr />
                  {fields.map((field, index) => (
                    <div key={field.id}>
                      <div className="form-group row">
                        <label className="col-3 col-form-label">Kind</label>
                        <div className="col-9">
                          <select {...register(`equipment_pickup[equipment_pickup_tickets_attributes].${index}.kind`)} onChange={e => setPickupData(prevState => ({...prevState, [index]: e.target.value }))} className="form-control">
                            <option value="">Select a kind</option>
                            <option value="pickup">Pickup</option>
                            <option value="dropoff">Dropoff</option>
                          </select>
                        </div>
                      </div>
                      {pickupData[index] === 'pickup' ? (
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Pickup Device</label>
                          <div className="col-9">
                            <select {...register(`equipment_pickup[equipment_pickup_tickets_attributes].${index}.inventory_device_uid`)} className="form-control">
                              <option value="">Select a device</option>
                              {
                                pickupDevices.map((device, pickupDeviceIndex) => {
                                  return (
                                    <option key={pickupDeviceIndex + index} value={device.id}>{device.model} - ({device.sticker_color} Sticker)</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      ) : null}
                      {pickupData[index] === 'dropoff' ? (
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Dropoff Device</label>
                          <div className="col-9">
                            <select {...register(`equipment_pickup[equipment_pickup_tickets_attributes].${index}.equipment_pickup_device_id`)} className="form-control">
                              <option value="">Select a device</option>
                              {
                                dropoff_devices.map((device, dropoffDeviceIndex) => {
                                  return (
                                    <option key={dropoffDeviceIndex + index} value={device.id}>{device.name} - ({device.description})</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      ) : null}
                      <button type="button" onClick={() => remove(index)} className='btn btn-danger float-right'>
                        Remove
                      </button>
                      <br />
                      <hr />
                      <br />
                      </div>
                  ))}
                </div>
                <button type="button" disabled={pickupLocation == null} onClick={() => append({})} className='btn btn-info'>
                  + Add Ticket
                </button>

                {/* <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Last Name</label>
                  <div className="col-lg-9">
                    <input type="text" value={ customer.last_name } className="form-control" />
                  </div>
                </div> */}

                {/* <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Email</label>
                  <div className="col-lg-9">
                    <input type="text" value={ customer.email } className="form-control" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Phone</label>
                  <div className="col-lg-9">
                    <input type="text" value={ customer.phone } className="form-control" />
                  </div>
                </div> */}
                <div className="text-right">
                  <input type="submit" className="btn btn-primary" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewEquipmentPickup