import React, {useEffect, useState} from 'react'
import {format, parseISO} from 'date-fns'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'

const Confirm = ({name, signup, setSignup, plans, csrf_token, setServerErrors, ...props}) => {
  const [submitting, setSubmitting] = useState()
  useEffect(() => {
    if (props.isActive) {
      // if (!signup.installation_date) {
      //   setServerErrors({missing_installation: ['Please select Installation Date']})
      // }
      if (!signup.plan_id) {
        setServerErrors({missing_plan: ['Please select a Plan']})
        props.goToStep(4)
      }
      if (!signup.uuid) props.goToStep(1)
    }
  }, [props.isActive])

  const ServiceDetails = () => {
    const selectedPlan = plans.find(plan => {
      return plan.id === signup.plan_id
    })
    return selectedPlan ? (
      <div className="col-md-4">
        <div id="installation-details">
          <div className="card mt-2">
            <div className="card-body">
              <h5 className="card-title">Service Details</h5>
              {selectedPlan.initial_fee ? (
                <>
                  <b>${selectedPlan.initial_fee} Connection Fee.</b>
                  <p>Not applied for CS Signup.</p>
                </>
              ) : null}
              <p>
                <b>{selectedPlan.name}</b>
                <br />$
                {selectedPlan.price.toFixed(2)}{' '}
                / mo.
              </p>
              <p>This will not be created for CS Signup, have customer finish signup with payment info to submit Stripe information.</p>
            </div>
          </div>
        </div>
      </div>
    ) : null
  }

  const confirmSignup = async selectedDate => {
    if (submitting) {
      return
    }

    setServerErrors(null)
    setSubmitting(true)
    const response = await fetch(`/cs_signup/${name}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        service_signup: {
          ...signup,
          signup_state: 'confirm',
        },
      }),
    })

    let respData = await response.json()
    if (respData !== null) {
      setSignup(respData.data)
      setSubmitting(false)
      if (respData.errors) {
        setServerErrors(respData.errors)
      } else {
        props.nextStep()
      }
    }
  }

  return (
    <div id="payment-wrapper">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col text-center">
            <h1>Payment</h1>
            <h2 className="h6">Review your service signup details</h2>
          </div>
        </div>
        <div className="row mb-4 justify-content-center">
          <div className="col-md-4">
            <div id="account-info">
              <div className="card mt-2">
                <div className="card-body">
                  <h5 className="card-title">Account Info</h5>
                  <p>
                    <b>Name</b>: {signup.first_name} {signup.last_name}
                    <br />
                    {signup.company_name && (
                      <>
                        <b>Company Name</b>: {signup.company_name}
                        <br />
                      </>
                    )}
                    <b>Email</b>: {signup.email}
                    <br />
                    <b>Phone Number</b>: {signup.phone}
                    <br />
                    <b>Address</b>: {signup.address_street} {signup.address_city}, {signup.address_state} {signup.address_zip}
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
          {signup.installation_date ? (
            <div className="col-md-4">
              <div id="installation-details">
                <div className="card mt-2">
                  <div className="card-body">
                    <h5 className="card-title">Installation Details</h5>
                    <p>
                      <b>Date:</b> {signup.installation_date && format(parseISO(signup.installation_date), 'MMMM dd, yyyy')}
                      <br />
                      <b>Time:</b> {signup.installation_date && format(parseISO(signup.installation_date), 'h:mm a')}
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <ServiceDetails />
        </div>
        <div className="row mb-4">
          <div className="col text-center">
            <button className="btn btn-primary my-3" onClick={() => confirmSignup()} disabled={submitting}>
              <span>Confirm Installation and Proceed</span> <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Confirm
