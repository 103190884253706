import React, { useEffect } from 'react'

const Complete = ({ signup, signup_complete, setSignup, ...props }) => {
  useEffect(() => {
    setSignup({})
  }, [])

  return (
    <div className="container-fluid px-5">
      <div className="row mb-4">
        {/* #completion-wrapper is used by google analytics */}
        <div className="col" id="completion-wrapper">
          <div dangerouslySetInnerHTML={{__html: signup_complete}} />
          {/* Email for Google analytics */}
          <input id="email" type='hidden' value={signup.email} />
        </div>
      </div>
    </div>
  )
}

export default Complete