import React, { useEffect } from 'react'

const Complete = ({...props }) => {
  return (
    <div className="container-fluid px-5">
      <div className="row mb-4">
        <div className="col">
            <h2 className="text-center">Thank you! The form has been submitted.</h2>
        </div>
      </div>
    </div>
  )
}

export default Complete