import React from 'react'

const Nav = props => {
  const stepNames = [
    {name: 'Account'},
    {name: 'Terms and Conditions'},
    {name: 'Schedule Installation'},
    {name: 'Select Plan'},
    {name: 'Payment'},
    {name: 'Complete'},
  ]
  const steps = []
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i
    const futureStep = i > props.currentStep
    const pastStep = i < props.currentStep
    steps.push(
      <li className={`breadcrumb-item ${pastStep ? 'clickable' : ''} ${isActive ? 'active' : ''}`} key={`step-${i}`}>
        {futureStep ? (
          <span className="text-disabled">{stepNames[i - 1].name}</span>
        ) : (
          <span onClick={() => props.goToStep(i)}>{stepNames[i - 1].name}</span>
        )}
      </li>,
    )
  }

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb justify-content-center">{steps}</ol>
    </nav>
  )
}

export default Nav
