import React, {useState, useEffect, useRef} from 'react'
import Calendar from 'react-calendar'
import {format, parseISO, isBefore, isAfter, setHours, add, startOfWeek, isWeekend, isSameWeek, setMinutes} from 'date-fns'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBan, faCheck, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import {currentDayTimeSlots} from '../event_scheduler/EventComponents'

const Schedule = ({signup, setSignup, setServerErrors, csrf_token, name, customSave, ...props}) => {
  const defaultDate = signup.scout_week_of ? parseISO(signup.scout_week_of) : setMinutes(setHours(add(startOfWeek(new Date()), {days: 15}), 7), 30)

  const maxDate = add(new Date(), {days: 60})
  const minDate = add(new Date(), {days: 5})

  const [dateValue, setDateValue] = useState(defaultDate)
  const [submitting, setSubmitting] = useState(null)

  useEffect(() => {
    if (!signup.uuid) props.goToStep(1)
  }, [])

  const defaultSaveScoutDate = async selectedDate => {
    if (submitting) {
      return
    }

    setServerErrors(null)
    setSubmitting(true)
    const {plan_id, addon_uids, installation_date, promotion_code, ...filteredSignup} = signup
    const response = await fetch(`/scout_signup/${name}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        service_signup: {
          ...filteredSignup,
          signup_state: 'schedule',
          scout_week_of: selectedDate,
        },
      }),
    })

    let respData = await response.json()
    if (respData !== null) {
      setSignup(respData.data)
      setSubmitting(false)
      if (respData.errors) {
        setServerErrors(respData.errors)
      } else {
        props.nextStep()
      }
    }
  }
  const saveScoutDate = customSave ? saveScoutDate : defaultSaveScoutDate

  const SelectedDateSummaryView = ({selectedDate}) => {
    const detailsRef = useRef()

    useEffect(() => {
      // Test suite fails without this check
      // detailsRef.current.scrollIntoView() is not a function
      if (typeof detailsRef.current.scrollIntoView === 'function') {
        detailsRef.current.scrollIntoView()
      }
    }, [selectedDate])

    return (
      <div id="installation-details" className="container-fluid">
        <div className="row justify-content-center">
          <div className="card mt-2">
            <div className="card-body">
              <h5 className="card-title" ref={detailsRef}>
                Scout Details
              </h5>
              {selectedDate ? (
                <>
                  {signup.uuid ? (
                    <>
                      <p>
                        <b>Week Of:</b> {format(selectedDate, 'MMMM dd, yyyy')}
                        <br />
                        <b>Location:</b> {signup.address_street} {signup.address_city}, {signup.address_state} {signup.address_zip}
                      </p>
                      <button className="btn btn-primary my-3" onClick={() => saveScoutDate(selectedDate)} disabled={submitting}>
                        <span>Confirm Scout Week and Complete</span> <FontAwesomeIcon icon={faArrowRight} />
                      </button>
                      {submitting ? <p>Submitting...</p> : null}
                    </>
                  ) : (
                    <>
                      <button className="btn btn-primary" onClick={() => props.goToStep(1)}>
                        Missing details, please return to account step.
                      </button>
                    </>
                  )}
                </>
              ) : (
                <button className="btn btn-dark my-3">
                  <span>Select Scout Week</span> <FontAwesomeIcon icon={faBan} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const tileDisabled = ({date, view}) => {
    if (view === 'month') {
      if (isWeekend(date)) return true
      if (isBefore(date, minDate)) return true
      if (isAfter(date, maxDate)) return true
      return false
    }
  }

  const tileClassName = ({date, view}) => {
    if (view === 'month') {
      if (isSameWeek(date, dateValue) && !isWeekend(date)) return 'week-selected'
      if (isWeekend(date)) return 'day-not-available'
      if (isBefore(date, minDate)) return 'day-not-available'
      if (isAfter(date, maxDate)) return 'day-not-available'
      return false
    }
  }

  const selectWeek = val => {
    const selectedWeek = setMinutes(setHours(add(startOfWeek(val), {days: 1}), 7), 30)
    setDateValue(selectedWeek)
  }

  return (
    <>
      <div className="container-fluid px-md-5">
        <div className="row mb-4">
          <div className="col text-center">
            <div id="calendar-wrapper">
              <Calendar
                calendarType="US"
                value={dateValue}
                onChange={selectWeek}
                tileClassName={tileClassName}
                tileDisabled={tileDisabled}
                minDate={minDate}
                maxDate={maxDate}
                minDetail="month"
                maxDetail="month"
                next2Label={null}
                prev2Label={null}
              />
            </div>
            <br />
            <SelectedDateSummaryView selectedDate={dateValue} />
          </div>
        </div>
      </div>
    </>
  )
}
export default Schedule
