import React, {useState, useEffect, useRef} from 'react'
import Calendar from 'react-calendar'

import {parseISO, getMinutes, setMinutes, getHours, setHours, add, sub, startOfWeek, startOfDay} from 'date-fns'
import {format, toDate, formatInTimeZone} from 'date-fns-tz'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBan, faCheck, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import {currentDayTimeSlots, tileClassName, tileDisabled, minDatePlusWeekend} from '../event_scheduler/EventComponents'

const Schedule = ({name, bookable_appointment, setServerErrors, csrf_token, todays_date, minimum_start, no_calendar_text, timezone, ...props}) => {
  const todaysDate = new Date(todays_date)
  const defaultDate = setHours(todaysDate, 0)

  const maxDate = add(todaysDate, {days: 60})
  const minDate = minDatePlusWeekend(todaysDate, bookable_appointment.schedule_minimum_days)

  const [timeSlots, setTimeSlots] = useState(null)
  const [dateValue, setDateValue] = useState(defaultDate)
  const [submitting, setSubmitting] = useState(null)

  function encodeQueryData(data) {
    const ret = []
    for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    return ret.join('&')
  }

  useEffect(() => {
    const controller = new AbortController()
    const getJobs = async () => {
      // NEW
      // const query = {
      //   timeslot: bookable_appointment.timeslot,
      //   entity: name
      // }
      // LEGACY
      const query = {
        blockoutEntity: bookable_appointment.signup_entity,
        calendarEntity: bookable_appointment.template_entity,
        timeslot: bookable_appointment.timeslot
      }
      // << LEGACY
      if (!!bookable_appointment.placeholder) query['placeholder'] = bookable_appointment.placeholder
      if (!!bookable_appointment.name) query['eventType'] = bookable_appointment.name
      const queryString = encodeQueryData(query)
      const response = await fetch(`/scheduling/time_slots?${queryString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf_token,
        },
      })
      const respData = await response.json()
      if (respData !== null) {
        setTimeSlots(respData)
        setSubmitting(false)
        if (respData.errors) {
          setServerErrors(respData.errors)
        }
      }
    }

    getJobs()

    return function cancel() {
      controller.abort()
    }
  }, [bookable_appointment.name])

  const saveAppointmentDate = async selectedDate => {
    if (submitting) {
      return
    }
    const formatString = 'yyyy-MM-dd HH:mm:ss'
    setServerErrors(null)
    setSubmitting(true)
    const response = await fetch(`/scheduling`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        bookable_appointment: {
          uuid: bookable_appointment.uuid,
          selected_time: toDate(format(selectedDate, 'yyyy-MM-dd HH:mm:ss')),
        },
      }),
    })

    let respData = await response.json()
    if (respData !== null) {
      setSubmitting(false)
      if (respData.errors) {
        setServerErrors(respData.errors)
      } else {
        props.nextStep()
      }
    }
  }
  const SelectedDateSummaryView = ({selectedDate}) => {
    const detailsRef = useRef()

    // useEffect(() => {
    //   // Test suite fails without this check
    //   // detailsRef.current.scrollIntoView() is not a function
    //   if (typeof detailsRef.current.scrollIntoView === 'function') {
    //     detailsRef.current.scrollIntoView()
    //   }
    // }, [selectedDate])

    return (
      <div id="installation-details" className="container-fluid">
        <div className="row justify-content-center">
          <div className="card mt-2">
            <div className="card-body">
              <h5 className="card-title" ref={detailsRef}>
                Details
              </h5>
              {selectedDate && getHours(selectedDate) !== 0 ? (
                <>
                  <p>
                    {bookable_appointment.duration_increment === 'week' ? <b>Week of: </b> : <b>Date: </b>}
                    {formatInTimeZone(selectedDate, timezone, 'MMMM dd, yyyy')}
                    {bookable_appointment.duration_increment === 'minute' ? (
                      <>
                        <br />
                        <b>Time:</b> {formatInTimeZone(selectedDate, timezone, 'h:mm a (zz)')}
                      </>
                    ) : null}
                  </p>
                  <button className="btn btn-primary my-3" onClick={() => saveAppointmentDate(selectedDate)} disabled={submitting}>
                    <span>Confirm</span> <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </>
              ) : (
                <button className="btn btn-dark my-3">
                  <span>Select Time</span> <FontAwesomeIcon icon={faBan} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const isTheSameHourAndMinutes = (event, compare) => {
    return getHours(event) === getHours(compare) && getMinutes(event) === getMinutes(compare)
  }

  const SelectedHourView = ({timeSlots, selectedDate}) => {
    const selectHour = event => {
      const dateWithEventHour = setHours(selectedDate, getHours(event))
      const dateWithEventMinutes = setMinutes(dateWithEventHour, getMinutes(event))
      setDateValue(dateWithEventMinutes)
    }

    return (
      <div id="time-selection" className="container-fluid">
        {formatInTimeZone(selectedDate, timezone, 'MMMM dd, yyyy')}
        <div className="row no-gutters">
          <div className="col-12 center">
            {currentDayTimeSlots(timeSlots, selectedDate).length > 0 ? (
              <div className="row no-gutters">
                {currentDayTimeSlots(timeSlots, selectedDate).map(timeSlot => {
                  const parsedDate = parseISO(timeSlot.start)
                  return (
                    <div key={timeSlot.start} className="col-6 col-md-4 px-1 py-1">
                      {isTheSameHourAndMinutes(parsedDate, selectedDate) ? (
                        <div className="btn btn-success w-100">
                          {formatInTimeZone(parsedDate, timezone, 'h:mm a (zz)')} <FontAwesomeIcon icon={faCheck} />
                        </div>
                      ) : !timeSlot.available || parsedDate < minimum_start ? (
                        bookable_appointment.override_allowed ? (
                          <div className="btn btn-dark w-100" onClick={() => selectHour(parsedDate)}>
                            {formatInTimeZone(parsedDate, timezone, 'h:mm a (zz)')} <FontAwesomeIcon icon={faBan} />
                          </div>
                        ) : (
                          <div className="btn btn-dark w-100">
                            {formatInTimeZone(parsedDate, timezone, 'h:mm a (zz)')} <FontAwesomeIcon icon={faBan} />
                          </div>
                        )
                      ) : (
                        <div className="btn btn-primary w-100" onClick={() => selectHour(parsedDate)}>
                          {formatInTimeZone(parsedDate, timezone, 'h:mm a (zz)')}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className="btn btn-success">No Hours Available...</div>
            )}
          </div>
        </div>
        <SelectedDateSummaryView selectedDate={selectedDate} />
      </div>
    )
  }

  const selectDateValue = val => {
    if (bookable_appointment.duration_increment === 'week') {
      // Add one day so beginning of week is Monday
      const selectedWeek = setMinutes(setHours(add(startOfWeek(val), {days: 1}), 7), 30)
      setDateValue(selectedWeek)
    } else if (bookable_appointment.duration_increment === 'day') {
      const selectedDay = setMinutes(setHours(startOfDay(val), 7), 30)
      setDateValue(selectedDay)
    } else {
      setDateValue(val)
    }
  }

  return (
    <>
      <div className="container-fluid px-md-5">
        <div className="row mb-4">
          <div className="col text-center">
            {timeSlots ? (
              <div id="calendar-wrapper">
                <Calendar
                  calendarType="US"
                  value={dateValue}
                  onChange={selectDateValue}
                  tileClassName={props =>
                    tileClassName({
                      ...props,
                      dateValue: dateValue,
                      increment: bookable_appointment.duration_increment,
                      minDate: minDate,
                      maxDate: maxDate,
                      timeSlots: timeSlots,
                    })
                  }
                  tileDisabled={props => bookable_appointment.override_allowed ? false : tileDisabled({...props, minDate: minDate, maxDate: maxDate, timeSlots: timeSlots}) }
                  minDate={minDate}
                  maxDate={maxDate}
                  minDetail="month"
                  next2Label={null}
                  prev2Label={null}
                />
                <br />
                {!bookable_appointment.override_allowed && tileDisabled({
                  date: dateValue,
                  view: 'month',
                  minDate: minDate,
                  maxDate: maxDate,
                  timeSlots: timeSlots,
                }) ? null : bookable_appointment.duration_increment === 'minute' ? (
                  <SelectedHourView timeSlots={timeSlots} selectedDate={dateValue} />
                ) : (
                  <SelectedDateSummaryView selectedDate={dateValue} />
                )}
              </div>
            ) : (
              <div>
                <div className="spinner-border text-primary" role="status"></div>
                <div>Loading Calendar...</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default Schedule
