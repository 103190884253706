import React, {useEffect, useState} from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'
import CheckoutForm from './CheckoutForm'
import {format, parseISO, set} from 'date-fns'

const Payment = ({name, signup, setSignup, plans, csrf_token, setServerErrors, auto_charge, addons, ...props}) => {
  const [promotion, setPromotion] = useState({})
  const [submitting, setSubmitting] = useState(null)

  const stripePromise = () => {
    return loadStripe(props.stripe_key)
  }

  useEffect(() => {
    if (props.isActive) {
      // if (!signup.installation_date) {
      //   setServerErrors({missing_installation: ['Please select Installation Date']})
      // }
      if (!signup.plan_id) {
        setServerErrors({missing_plan: ['Please select a Plan']})
      }
      if (!signup.uuid) props.goToStep(1)
    }
  }, [props.isActive])

  const PromoCode = () => {
    const validatePromotion = async code => {
      if (submitting) {
        return
      }

      setServerErrors(null)
      setSubmitting(true)
      const response = await fetch(`/service_signup/validate_promotion`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf_token,
        },
        body: JSON.stringify({
          service_signup: {
            uuid: signup.uuid,
          },
          promotion_code: code,
        }),
      })

      let respData = await response.json()
      if (respData !== null) {
        if (respData.data) {
          if (respData.data.active === true) {
            setSignup({
              ...signup,
              promotion_code: code,
            })
            setPromotion(respData.data)
          } else {
            setSignup({
              ...signup,
              promotion_code: '',
            })
            setPromotion(respData.data)
          }
        }
        setSubmitting(false)
        if (respData.errors) {
          setPromotion({})
          setServerErrors(respData.errors)
        }
      }
    }

    const [tempCode, setTempCode] = useState('')
    const onChange = event => {
      setTempCode(event.target.value)
    }
    return (
      <form>
        <input placeholder="Promo Code" className="m-2" name="code" value={tempCode} onChange={onChange} />
        <button onClick={() => validatePromotion(tempCode)} className="btn btn-primary" disabled={submitting}>
          Check Promo
        </button>
        {submitting ? 'Validating...' : promotion.active === false ? <span className="text-danger"> invalid code</span> : ''}
      </form>
    )
  }

  const calculatedFee = (initialFee, promo) => {
    if (promo.active && promo.coupon.percent_off) {
      return initialFee - initialFee * (promo.coupon.percent_off / 100)
    } else if (promo.active && promo.coupon.amount_off) {
      return initialFee - promo.coupon.amount_off
    } else {
      return initialFee
    }
  }

  const ServiceDetails = () => {
    const selectedPlan = plans.find(plan => {
      return plan.id === signup.plan_id
    })
    function selectedAddons() {
      return addons.filter(addon => {
        return signup.addon_uids.split(',').includes(addon.id.toString())
      })
    }
    function total() {
      let totalAmount = selectedPlan.price
      selectedAddons().forEach((addon) => {
        totalAmount = totalAmount + addon.price
      })
      return totalAmount
    }
    return selectedPlan ? (
      <div className="col-md-4">
        <div id="installation-details">
          <div className="card mt-2">
            <div className="card-body">
              <h5 className="card-title">Service Details</h5>
              {selectedPlan.initial_fee ? (
                <>
                  <b>${calculatedFee(selectedPlan.initial_fee, promotion)} Connection Fee.</b>
                  {auto_charge ? <p>Due immediately.</p> : <p>Due on installation date.</p>}
                </>
              ) : null}
              {promotion && promotion.coupon && promotion.active ? (
                <p className="alert alert-success">Promo Applied: {promotion.coupon.name}</p>
              ) : null}
              <p>
                <b>{selectedPlan.name}</b>
                <br />$
                {selectedPlan.price % 1 === 0 ? selectedPlan.price : selectedPlan.price.toFixed(2)}{' '}
                / mo.
              </p>
              {selectedAddons().map(addon => (
                <p key={addon.id}>
                  <b>{addon.name}</b>
                  <br />
                  ${addon.price} / mo.
                </p>
              ))}
              <p>
                <b>Monthly Total</b><br />
                ${total().toFixed(2)} / mo.
              </p>

              <br />
              <p>(Your monthly payments will begin the day after your installation and will be automatically charged monthly)</p>
            </div>
          </div>
        </div>
      </div>
    ) : null
  }

  return (
    <div id="payment-wrapper">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col text-center">
            <h1>Payment</h1>
            <h2 className="h6">Review your service signup details</h2>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-4">
            <div id="account-info">
              <div className="card mt-2">
                <div className="card-body">
                  <h5 className="card-title">Account Info</h5>
                  <p>
                    <b>Name</b>: {signup.first_name} {signup.last_name}
                    <br />
                    {signup.company_name && (
                      <>
                        <b>Company Name</b>: {signup.company_name}
                        <br />
                      </>
                    )}
                    <b>Email</b>: {signup.email}
                    <br />
                    <b>Phone Number</b>: {signup.phone}
                    <br />
                    <b>Address</b>: {signup.address_street} {signup.address_city}, {signup.address_state} {signup.address_zip}
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="installation-details">
              <div className="card mt-2">
                <div className="card-body">
                  <h5 className="card-title">Installation Details</h5>
                  <p>
                    <b>Date:</b> {signup.installation_date && format(parseISO(signup.installation_date), 'MMMM dd, yyyy')}
                    <br />
                    <b>Time:</b> {signup.installation_date && format(parseISO(signup.installation_date), 'h:mm a')}
                    <br />
                  </p>
                </div>
              </div>
              {/* {promo-code modelCode=model.promoCode} */}
              <PromoCode />
            </div>
          </div>
          <ServiceDetails />
        </div>
        <div className="row mb-4">
          <div className="col text-center">
            <Elements stripe={stripePromise()}>
              <CheckoutForm
                name={name}
                planId={signup.planId}
                signup={signup}
                setServerErrors={setServerErrors}
                validatingPromo={submitting}
                csrf_token={csrf_token}
                {...props}
              />
            </Elements>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Payment
