import {differenceInCalendarDays, setHours} from 'date-fns'
import React, {useEffect, useState} from 'react'
import Calendar from 'react-calendar'
import Select from 'react-select'
import {format} from 'date-fns-tz'

const ProrateCalculation = ({currentPlan, plans}) => {
  const todaysDate = new Date()
  const defaultDate = setHours(todaysDate, 0)
  const [dateValue, setDateValue] = useState(defaultDate)

  const [selectedPlan, setSelectedPlan] = useState(null)
  const [results, setResults] = useState(null)

  useEffect(() => {
    if (selectedPlan) {
      const diff = selectedPlan.price - currentPlan.price
      const rate = diff / 30

      setResults({
        difference: diff,
        dailyRate: rate,
        finalInvoiceAmount: differenceInCalendarDays(dateValue, todaysDate) * rate,
      })
    }
  }, [selectedPlan, dateValue])

  function handleSelection(event) {
    setSelectedPlan(event.plan)
  }

  function twoDecimal(val) {
    return parseInt(val * 100) / 100
  }

  const tileClassName = ({date, view}) => {
    // if (view === 'month') {
    //   const found = disabled_dates.find(disabled => {
    //     return differenceInCalendarDays(date, parseISO(disabled.date)) === 0
    //   })
    //   if (found) return 'day-not-available'
    // }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 mb-4">
          <p>
            Current Plan {currentPlan.name} {selectedPlan ? `| Selected Plan ${selectedPlan.name}` : null}
          </p>

          {selectedPlan && results ? (
            <ul>
              <li>
                Selected amount: ${selectedPlan.price} - current amount: ${currentPlan.price} = ${results.difference}
              </li>
              <li>Todays Date: {format(todaysDate, 'MMMM dd, yyyy')}</li>
              <li>Billing Anchor Date: {format(dateValue, 'MMMM dd, yyyy')}</li>
              <li>Difference in calendar days: {differenceInCalendarDays(dateValue, todaysDate)}</li>
              <li>Daily Rate: ${twoDecimal(results.dailyRate)}</li>
              <li>Invoice Amount: ${results.finalInvoiceAmount}</li>
            </ul>
          ) : null}
        </div>
        <div className="col-6">
          <div className="select-">
            <Select
              placeholder="Select Plan..."
              options={plans.map(e => {
                return {label: e.name, value: e.id, plan: e}
              })}
              onChange={handleSelection}
            />
          </div>
          <div id="mini-calendar-wrapper">
            <p className='my-2'>Select billing anchor data.</p>
            <Calendar calendarType="US" value={dateValue} onChange={setDateValue} tileClassName={tileClassName} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProrateCalculation
