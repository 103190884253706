import React, {useState} from 'react'
import '../stylesheets/Signup.scss'
import {formatInTimeZone} from 'date-fns-tz'

const formatTimestamp = (timestamp, timezone) => {
  const date = new Date(timestamp * 1000);
  return formatInTimeZone(date, timezone, 'MMMM dd, yyyy h:mm a (zz)');
}
const OverdueBilling = ({invoices, timezone, entity, params}) => {
  const [hiddenFields, setHiddenFields] = useState(JSON.parse(localStorage.getItem('listInvoicesHiddenFields')) || [])
  const handleHiddenFieldToggle = (e) => {
    if (hiddenFields.includes(e.target.value)) {
      localStorage.setItem('listInvoicesHiddenFields', JSON.stringify(hiddenFields.filter((field) => field !== e.target.value)))
    } else {
      localStorage.setItem('listInvoicesHiddenFields', JSON.stringify(hiddenFields.concat(e.target.value)))
    }
    setHiddenFields(JSON.parse(localStorage.getItem('listInvoicesHiddenFields')))
  }
  if (invoices === null) {
    return <div className="container py-4"><h1>Loading...</h1></div>
  }
  if (invoices.length === 0) {
    return <div className="container py-4"><h1>No overdue invoices found.</h1></div>
  }
  const parsedParams = JSON.parse(params)
  const descCreated = parsedParams.direction === "desc" && parsedParams.sort_by === 'created'
  const descDueDate = parsedParams.direction === "desc" && parsedParams.sort_by === 'due_date'
  return (
    <div className="px-4 py-4">
      <h1>Overdue Invoices</h1>
      <div id="hidden-field-checkbox" className="mb-4 d-flex justify-content-center align-items-center">
        <button className="btn btn-sm btn-primary" onClick={() => {
          localStorage.removeItem('listInvoicesHiddenFields')
          setHiddenFields([])
        }}>Show all Fields</button>
        <div className="p-1">
          Hidden Fields:
        </div>
        <div className="mt-2 px-1">
          <label className="mx-1" htmlFor="state">State</label>
          <input type="checkbox" id="state" value="state" onChange={handleHiddenFieldToggle} checked={hiddenFields.includes('state')} />
        </div>
        <div className="mt-2 px-1">
          <label className="mx-1" htmlFor="total">Total</label>
          <input type="checkbox" id="total" value="total" onChange={handleHiddenFieldToggle} checked={hiddenFields.includes('total')} />
        </div>
        <div className="mt-2 px-1">
          <label className="mx-1" htmlFor="invoice_id">Invoice ID</label>
          <input type="checkbox" id="invoice_id" value="invoice_id" onChange={handleHiddenFieldToggle} checked={hiddenFields.includes('invoice_id')} />
        </div>
        <div className="mt-2 px-1">
          <label className="mx-1" htmlFor="stripe_customer">Stripe Customer</label>
          <input type="checkbox" id="stripe_customer" value="stripe_customer" onChange={handleHiddenFieldToggle} checked={hiddenFields.includes('stripe_customer')} />
        </div>
        <div className="mt-2 px-1">
          <label className="mx-1" htmlFor="crm_customer">CRM Customer</label>
          <input type="checkbox" id="crm_customer" value="crm_customer" onChange={handleHiddenFieldToggle} checked={hiddenFields.includes('crm_customer')} />
        </div>
        <div className="mt-2 px-1">
          <label className="mx-1" htmlFor="tags">Tags</label>
          <input type="checkbox" id="tags" value="tags" onChange={handleHiddenFieldToggle} checked={hiddenFields.includes('tags')} />
        </div>
        <div className="mt-2 px-1">
          <label className="mx-1" htmlFor="created">Created</label>
          <input type="checkbox" id="created" value="created" onChange={handleHiddenFieldToggle} checked={hiddenFields.includes('created')} />
        </div>
        <div className="mt-2 px-1">
          <label className="mx-1" htmlFor="due_date">Due Date</label>
          <input type="checkbox" id="due_date" value="due_date" onChange={handleHiddenFieldToggle} checked={hiddenFields.includes('due_date')} />
        </div>
        {/* <div className="mt-2 px-1">
          <label className="mx-1" htmlFor="days_past_due">Days Past Due</label>
          <input type="checkbox" id="days_past_due" value="days_past_due" onChange={handleHiddenFieldToggle} checked={hiddenFields.includes('days_past_due')} />
        </div> */}
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            { hiddenFields.includes('state') ? null : <th className="border">State</th> }
            { hiddenFields.includes('total') ? null : <th className="border">Total</th> }
            { hiddenFields.includes('invoice_id') ? null : <th className="border">Invoice ID</th> }
            { hiddenFields.includes('stripe_customer') ? null : <th className="border">Stripe Customer</th> }
            { hiddenFields.includes('crm_customer') ? null : <th className="border">CRM Customer</th> }
            { hiddenFields.includes('tags') ? null : <th className="border">Tags</th> }
            { hiddenFields.includes('created') ? null : <th className="border"><a href={`?sort_by=created&direction=${descCreated ? "asc" : "desc" }`} className={parsedParams.sort_by === 'created' ? null : 'text-black'}>Created {parsedParams.sort_by === 'created' ? descCreated ? <i className="icon-arrow-down15 mr-2"></i> : <i className="icon-arrow-up15 mr-2"></i> : null}</a></th> }
            { hiddenFields.includes('due_date') ? null : <th className="border"><a href={`?sort_by=due_date&direction=${descDueDate ? "asc" : "desc" }`} className={parsedParams.sort_by === 'due_date' || !parsedParams.sort_by ? null : 'text-black'}>Due Date {parsedParams.sort_by === 'due_date' || !parsedParams.sort_by ? descDueDate || !parsedParams.direction ? <i className="icon-arrow-down15 mr-2"></i> : <i className="icon-arrow-up15 mr-2"></i> : null}</a></th> }
            {/* { hiddenFields.includes('days_past_due') ? null : <th className="border">Days Past Due</th> } */}
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, index) => {
            const created = formatTimestamp(invoice.created, timezone)
            const due_date = invoice.due_date ? formatTimestamp(invoice.due_date, timezone) : null
            let state;
            if (invoice.inferred_state == 'Pending') {
              state = <span className="text-primary">Pending</span>;
            } else if (invoice.inferred_state == 'Retrying') {
              state = <span className="text-warning">Retrying</span>;
            } else if (invoice.inferred_state == 'Failed') {
              state = <span className="text-danger">Failed</span>;
            } else if (invoice.inferred_state == 'Past Due') {
              state = <span>Past Due</span>;
            } else {
              state = <span>Past Due</span>;
            }
            return (
              <tr className={index % 2 === 0 ? 'bg-dark-100' : null} key={invoice.id}>
                { hiddenFields.includes('state') ? null : <td className="border" style={{padding: '0.2rem 0.5rem'}}>{state}</td> }
                { hiddenFields.includes('total') ? null : <td className="border" style={{padding: '0.2rem 0.5rem'}}>${(invoice.total / 100).toFixed(2)}</td> }
                { hiddenFields.includes('invoice_id') ? null : <td className="border" style={{padding: '0.2rem 0.5rem'}}>
                  <a href={`https://dashboard.stripe.com/invoices/${invoice.id}`} target="_blank" rel="noopener noreferrer">
                    {invoice.id}
                  </a>
                </td> } 
                { hiddenFields.includes('stripe_customer') ? null : <td className="border" style={{padding: '0.2rem 0.5rem'}}>
                  <div>{invoice.customer_name}</div>
                  <div>
                    <a href={`https://dashboard.stripe.com/customers/${invoice.customer}`} target="_blank" rel="noopener noreferrer">
                      {invoice.customer_email}
                    </a>
                  </div>
                </td> }
                { hiddenFields.includes('crm_customer') ? null : <td className="border" style={{padding: '0.2rem 0.5rem'}}>
                  {invoice.crm_client_uid ? <a className="d-inline-block btn btn-primary py-0 px-1 text-sm" href={`${entity.uisp_url}/crm/client/${invoice.crm_client_uid}`}>UCRM</a> : ""} {invoice.jarvis_customer_uid ? <a className="inline btn btn-primary py-0 px-1 text-sm" href={`/crm/${entity.name}/customers/${invoice.jarvis_customer_uid}`}>Jarvis</a> : ""}
                </td> }
                { hiddenFields.includes('tags') ? null : <td className="border" style={{padding: '0.2rem 0.5rem'}}>
                  {invoice.customer_tags && invoice.customer_tags.map((tag) => {
                    return <span key={tag.name} className="badge badge-secondary text-sm ml-1" style={{fontSize: '0.5rem', backgroundColor: tag.color}}>{tag.name}</span>
                  })}
                </td> }
                { hiddenFields.includes('created') ? null : <td className="border" style={{padding: '0.2rem 0.5rem'}}>{created}</td> }
                { hiddenFields.includes('due_date') ? null : <td className="border" style={{padding: '0.2rem 0.5rem'}}>{due_date || <span className='opacity-50'>{created}</span>}</td> }
                {/* { hiddenFields.includes('days_past_due') ? null : <td className="border" style={{padding: '0.2rem 0.5rem'}}>{invoice.days_past_due}</td> } */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}

const Invoices = ({initial_pagination, initial_invoices, timezone, entity, params}) => {
  const [invoices, setInvoices] = useState(initial_invoices)
  const [pagination, setPagination] = useState(initial_pagination)

  const fetchInvoices = (startingAfter = null, endingBefore = null) => {
    let url = `/crm/${entity.name}/invoices.json`
    if (startingAfter) {
      url += `?starting_after=${startingAfter}`
    } else if (endingBefore) {
      url += `?ending_before=${endingBefore}`
    }

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
    })
      .then(response => response.json())
      .then(data => {
        setInvoices(data.invoices)
        setPagination(data.pagination)
      }).catch(error => console.error('Error fetching invoices:', error))
  }
  return (
    <div>
      <OverdueBilling invoices={invoices} timezone={timezone} entity={entity} params={params} />
      <div className='text-center mb-4'>
        {pagination.previous_ending_before && <button className="btn btn-indigo btn-sm" onClick={() => fetchInvoices(null, pagination.previous_ending_before)}>Previous</button>}
        {pagination.next_starting_after && <button className="btn btn-indigo btn-sm" onClick={() => fetchInvoices(pagination.next_starting_after)}>Next</button>}
      </div>
    </div>
  )
}

export default Invoices
