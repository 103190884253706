import React from 'react'

const Nav = props => {
  const stepNames = [
    {name: 'Schedule appointment'},
    {name: 'Complete'},
  ]
  if (props.bookable_appointment.greeting_step) stepNames.unshift({name: 'Greeting'})
  const steps = []
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i
    steps.push(
      <li className={`breadcrumb-item ${isActive ? 'active' : ''}`} key={`step-${i}`}>
        <span>{stepNames[i - 1].name}</span>
      </li>,
    )
  }

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb justify-content-center">{steps}</ol>
    </nav>
  )
}

export default Nav
