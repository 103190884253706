import {getDay,setHours,setMinutes} from 'date-fns'

const EntityHours = (selectedDate, scheduling_hours) => {
  const hours = []
  const setHoursAndMinutes = (selectedDate, hour) => {
    let date = setHours(selectedDate, hour.split(':')[0])
    date = setMinutes(date, hour.split(':')[1])
    return date
  }

  if (scheduling_hours) {
    const keys = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
    }
    scheduling_hours.split(',').forEach(scheduling_hour => {
      const hour = scheduling_hour.split('|')[0]
      const day = scheduling_hour.split('|')[1]
      if (day === undefined || keys[day] === getDay(selectedDate)) {
        const date = setHoursAndMinutes(selectedDate, hour)
        hours.push(date)
      }
    })
    return hours
  } else {
    return []
  }
}

export default EntityHours
