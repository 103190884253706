import React, {useState, useEffect, useRef} from 'react'
import Calendar from 'react-calendar'
import EntityHours from '../utils/EntityHours'

import {
  differenceInCalendarDays,
  format,
  parseISO,
  isBefore,
  isAfter,
  getMinutes,
  setMinutes,
  getHours,
  setHours,
  add,
  sub,
  isWeekend,
} from 'date-fns'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBan, faCheck, faArrowRight} from '@fortawesome/free-solid-svg-icons'

// {
//   date: "2021-01-20T15:30:00-0700"
//   duration: 60
// }

const Schedule = ({signup, setSignup, setServerErrors, csrf_token, name, calendar_entities, scheduling_hours, ...props}) => {
  const defaultDate = signup.installation_date ? parseISO(signup.installation_date) : setHours(add(new Date(), {days: 7}), 0)

  const maxDate = add(new Date(), {days: 60})
  const minDate = add(new Date(), {days: 5})

  const [jobs, setJobs] = useState(null)
  const [dateValue, setDateValue] = useState(defaultDate)
  const [submitting, setSubmitting] = useState(null)

  useEffect(() => {
    if (!signup.uuid) {
      setServerErrors({'Missing uuid': ['Please submit account info before proceeding.']})
      props.goToStep(1)
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()

    const getJobs = async () => {
      const entityArray = JSON.stringify(calendar_entities.split(','))
      const response = await fetch('/service_signup/jobs?entities=' + entityArray, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf_token,
        },
      })

      const respData = await response.json()

      if (respData !== null) {
        const withParsedDate = respData.map(obj => ({
          ...obj,
          parsedDate: parseISO(obj.date),
        }))
        setJobs(withParsedDate)
        setSubmitting(false)
        if (respData.errors) {
          setServerErrors(respData.errors)
        }
      }
    }
    if (calendar_entities) {
      getJobs()
    }

    return function cancel() {
      controller.abort()
    }
  }, [name])

  const saveInstallationDate = async selectedDate => {
    if (submitting) {
      return
    }

    setServerErrors(null)
    setSubmitting(true)
    const response = await fetch(`/service_signup/${name}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        service_signup: {
          ...signup,
          signup_state: 'schedule',
          installation_date: selectedDate,
        },
      }),
    })

    let respData = await response.json()
    if (respData !== null) {
      setSignup(respData.data)
      setSubmitting(false)
      if (respData.errors) {
        setServerErrors(respData.errors)
      } else {
        props.nextStep()
      }
    }
  }
  const SelectedDateSummaryView = ({selectedDate}) => {
    const detailsRef = useRef()

    useEffect(() => {
      detailsRef.current.scrollIntoView()
    }, [selectedDate])

    return (
      <div id="installation-details" className="container-fluid">
        <div className="row justify-content-center">
          <div className="card mt-2">
            <div className="card-body">
              <h5 className="card-title" ref={detailsRef}>
                Installation Details
              </h5>
              {selectedDate && getHours(selectedDate) !== 0 ? (
                <>
                  {signup.uuid ? (
                    <>
                      <p>
                        <b>Date:</b> {format(selectedDate, 'MMMM dd, yyyy')}
                        <br />
                        <b>Time:</b> {format(selectedDate, 'h:mm a')}
                        <br />
                        <b>Location:</b> {signup.address_street} {signup.address_city}, {signup.address_state} {signup.address_zip}
                      </p>
                      <button className="btn btn-primary my-3" onClick={() => saveInstallationDate(selectedDate)} disabled={submitting}>
                        <span>Confirm Installation Date and Proceed</span> <FontAwesomeIcon icon={faArrowRight} />
                      </button>
                    </>
                  ) : (
                    <>
                      <button className="btn btn-primary" onClick={() => props.goToStep(1)}>
                        Missing details, please return to account step.
                      </button>
                    </>
                  )}
                </>
              ) : (
                <button className="btn btn-dark my-3">
                  <span>Select Installation Date and Time</span> <FontAwesomeIcon icon={faBan} />
                </button>
              )}
              <br />
              <button className="btn btn-primary my-3" onClick={() => saveInstallationDate(null)}>
                <span>Skip Date Selection</span>{' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const SelectedHourView = ({selectedDate}) => {
    const currentDayEvents = () => {
      if (jobs) {
        return jobs.filter(dDate => {
          return differenceInCalendarDays(dDate.parsedDate, selectedDate) === 0
        })
      } else {
        return []
      }
    }

    const isBooked = hour => {
      // See if there is anything scheduled for the current time period
      const events = currentDayEvents().filter(currentDayEvent => {
        const currentDayEventEnd = add(currentDayEvent.parsedDate, {
          minutes: currentDayEvent.duration,
        })
        // Is the currentDayEvent after the start time and before the end time of the entityEvent
        return isAfter(hour, sub(currentDayEvent.parsedDate, {minutes: 1})) && isBefore(hour, currentDayEventEnd)
      })
      // If anything is within the time frame, the event is booked
      return events.length > 0
    }

    const isTheSameHourAndMinutes = (event, compare) => {
      return getHours(event) === getHours(compare) && getMinutes(event) === getMinutes(compare)
    }

    const selectHour = event => {
      const dateWithEventHour = setHours(selectedDate, getHours(event))
      const dateWithEventMinutes = setMinutes(dateWithEventHour, getMinutes(event))
      setDateValue(dateWithEventMinutes)
    }

    return (
      <div id="time-selection" className="container-fluid">
        {format(selectedDate, 'MMMM dd, yyyy')}
        <div className="row no-gutters">
          <div className="col-12 center">
            {EntityHours(selectedDate, scheduling_hours).length > 0 ? (
              <div className="row no-gutters">
                {EntityHours(selectedDate, scheduling_hours).map(entityHour => (
                  <div key={entityHour} className="col-6 col-md-4 px-1 py-1">
                    {isBooked(entityHour) ? (
                      <div className="btn btn-dark w-100">
                        {format(entityHour, 'h:mm a')} <FontAwesomeIcon icon={faBan} />
                      </div>
                    ) : isTheSameHourAndMinutes(entityHour, selectedDate) ? (
                      <div className="btn btn-success w-100">
                        {format(entityHour, 'h:mm a')} <FontAwesomeIcon icon={faCheck} />
                      </div>
                    ) : (
                      <div className="btn btn-primary w-100" onClick={() => selectHour(entityHour)}>
                        {format(entityHour, 'h:mm a')}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="btn btn-success">No Hours Available...</div>
            )}
          </div>
        </div>
        <SelectedDateSummaryView selectedDate={selectedDate} />
      </div>
    )
  }

  return (
    <>
      <div className="container-fluid px-md-5">
        <div className="row mb-4">
          <div className="col text-center">
            {jobs ? (
              <div id="calendar-wrapper">
                <Calendar calendarType="US" value={dateValue} onChange={setDateValue} minDetail="month" next2Label={null} prev2Label={null} />
                <br />
                <SelectedHourView selectedDate={dateValue} />
              </div>
            ) : calendar_entities ? (
              <div>
                <div className="spinner-border text-primary" role="status"></div>
                <div>Loading Calendar...</div>
              </div>
            ) : (
              <div>
                {<div dangerouslySetInnerHTML={{__html: no_calendar_text}} />}
                <button className="btn btn-primary my-3" onClick={() => props.nextStep()}>
                  <span>Proceed</span> <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default Schedule
