import React, {useState, ReactDOM, useEffect} from 'react'
import moment from 'moment'

const ActivityFeed = ({events, event_types, ...props}) => {
  const [activeTab, setActiveTab] = useState('all')
  const [filteredEvents, setFilteredEvents] = useState(events)

  const iconEventMap = {
    call: { icon: 'icon-phone-incoming', color: 'border-success text-success' },
    email: { icon: 'icon-envelop2', color: 'border-primary text-primary' },
    voicemail: { icon: 'icon-play3', color: 'border-teal text-teal' },
    sms: { icon: 'icon-comment', color: 'border-teal text-teal' },
    note: { icon: 'icon-pencil', color: 'border-info text-info' }
  }

  useEffect(() => {
    if (activeTab === 'all') {
      setFilteredEvents(events)
    } else {
      setFilteredEvents(events.filter(event => event.event_type === activeTab))
    }
  }, [activeTab])

  const showModal = (type, event) => {
    if (type === 'edit') {
      console.log('Edit Event', event)
    } else {
      console.log('Show Event', event)
    }
  }

  return (
    <div className="card">
      <div className="card-header header-elements-inline">
        <h6 className="card-title">Event Feed</h6>
        <div className="header-elements">
          <span className="badge badge-info badge-pill">{events.length}</span>
        </div>
      </div>

      <ul className="nav nav-tabs nav-tabs-bottom nav-justified mb-0">
        <li className="nav-item">
          <a href="#" className={"nav-link active"} data-toggle="tab" onClick={() => setActiveTab('all')} >All</a>
        </li>
        {event_types.map((event_type, index) => {
          return (
            <li className="nav-item" key={index}>
              <a href={`#messages-${event_type}`} className={"nav-link"} data-toggle="tab" onClick={() => setActiveTab(event_type.value)}> {event_type.name} </a>
            </li>
          )
        })}
      </ul>

      <div className="card-body">
        <div className="chart mb-3" id="bullets"></div>

        <ul className="media-list">
          {filteredEvents.map((event, index) => {
            return (
              <li key={index} className="media">
                <div className="mr-3">
                  <a href="#" className={`btn bg-transparent ${iconEventMap[event.event_type].color} rounded-pill border-2 btn-icon`}><i className={iconEventMap[event.event_type].icon}></i></a>
                </div>

                <div className="media-body">
                  <a href="#">{event.subject}</a>
                  <div>{event.notes}</div>
                  <div className="text-muted" title={moment(event.created_at).format('MMMM Do YYYY, h:mm:ss a')}>{moment(event.created_at).fromNow()}</div>
                </div>

                <div className="dropdown ml-3">
                  <a href="#" className="list-icons-item dropdown-toggle" data-toggle="dropdown"></a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a href="#" className="dropdown-item" onClick={() => showModal('edit', event)}>Edit</a>
                    <a href="#" className="dropdown-item" onClick={() => showModal('show', event)}>Show</a>
                  </div>
                </div>
              </li>
            )
          })}

          {/* <li className="media">
            <div className="mr-3">
              <a href="#" className="btn bg-transparent border-success text-success rounded-pill border-2 btn-icon"><i className="icon-checkmark3"></i></a>
            </div>

            <div className="media-body">
              Invoices <a href="#">#4732</a> and <a href="#">#4734</a> have been paid
              <div className="text-muted">Dec 18, 18:36</div>
            </div>

            <div className="ml-3 align-self-center">
              <a href="#" className="list-icons-item"><i className="icon-more"></i></a>
            </div>
          </li>

          <li className="media">
            <div className="mr-3">
              <a href="#" className="btn bg-transparent border-primary text-primary rounded-pill border-2 btn-icon"><i className="icon-alignment-unalign"></i></a>
            </div>

            <div className="media-body">
              Affiliate commission for June has been paid
              <div className="text-muted">36 minutes ago</div>
            </div>

            <div className="ml-3 align-self-center">
              <a href="#" className="list-icons-item"><i className="icon-more"></i></a>
            </div>
          </li>

          <li className="media">
            <div className="mr-3">
              <a href="#" className="btn bg-transparent border-warning text-warning rounded-pill border-2 btn-icon"><i className="icon-spinner11"></i></a>
            </div>

            <div className="media-body">
              Order <a href="#">#37745</a> from July, 1st has been refunded
              <div className="text-muted">4 minutes ago</div>
            </div>

            <div className="ml-3 align-self-center">
              <a href="#" className="list-icons-item"><i className="icon-more"></i></a>
            </div>
          </li>

          <li className="media">
            <div className="mr-3">
              <a href="#" className="btn bg-transparent border-teal text-teal rounded-pill border-2 btn-icon"><i className="icon-redo2"></i></a>
            </div>

            <div className="media-body">
              Invoice <a href="#">#4769</a> has been sent to <a href="#">Robert Smith</a>
              <div className="text-muted">Dec 12, 05:46</div>
            </div>

            <div className="ml-3 align-self-center">
              <a href="#" className="list-icons-item"><i className="icon-more"></i></a>
            </div>
          </li> */}
        </ul>
      </div>
    </div>

  )
}

export default ActivityFeed;