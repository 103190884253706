import React, { useState, useEffect } from 'react'
import '../stylesheets/Signup.scss'
import StepWizard from 'react-step-wizard'
import Schedule from './bookable_appointment/Schedule'
import Nav from './bookable_appointment/Nav'
import Greeting from './bookable_appointment/Greeting'
import Complete from './bookable_appointment/Complete'

const BookableAppointment = (props) => {
  const [serverErrors, setServerErrors] = useState(null)

  return (
    <div className="jarvis-event-scheduler p-3">
      {serverErrors ? (
        <div className="alert alert-danger" role="alert">
          {Object.keys(serverErrors).map(error => {
            return (
              <div key={error}>
                {error}: {serverErrors[error][0]}
              </div>
            )
          })}
        </div>
      ) : null}

      <StepWizard isHashEnabled={true} nav={<Nav {...props} />} isLazyMount={true}>
        {props.bookable_appointment.greeting_step ? (
          <Greeting setServerErrors={setServerErrors} {...props} />
        ) : null}
        <Schedule setServerErrors={setServerErrors} {...props} />
        <Complete {...props} />
      </StepWizard>
    </div>
  )
}

export default BookableAppointment
