import React from 'react'

const DeviceMapLabel = ({ device }) => {
  return (
    <>
      <p>{device.name}</p>
      <p>Model: {device.model}</p>
      <p>Serial: {device.serial}</p>
    </>
  )
}

export default DeviceMapLabel