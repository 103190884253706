import React, { useEffect } from 'react'

const Greeting = ({ bookable_appointment, ...props }) => {
  function proceed() {
    props.nextStep()
  }
  return (
    <div className="container-fluid px-5">
      <div className="row mb-4">
        <div className="col">
          <div dangerouslySetInnerHTML={{__html: bookable_appointment.greeting_step}} />
          <div className="text-center">
            <button onClick={() => proceed()} className="btn btn-primary my-3">
              <span>Proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Greeting