import React, { useState, useEffect } from 'react'
import '../stylesheets/Signup.scss'
import StepWizard from 'react-step-wizard'
import Account from './request/Account'
import Complete from './request/Complete'

const Signup = (props) => {
  const [serverErrors, setServerErrors] = useState(null)
  const [signup, setSignup] = useState(() => JSON?.parse(localStorage.getItem('currentSignup')) || {key: 1})

  return (
    <div className="jarvis-signup p-3">
      {serverErrors ? (
        <div className="alert alert-danger" role="alert">
          {Object.keys(serverErrors).map(error => {
            return (
              <div key={error}>
                {error}: {serverErrors[error][0]}
              </div>
            )
          })}
        </div>
      ) : null}
      <StepWizard isHashEnabled={true} isLazyMount={true}>
        <Account signup={signup} setSignup={setSignup} setServerErrors={setServerErrors} {...props} />
        <Complete signup={signup} setSignup={setSignup} setServerErrors={setServerErrors} {...props} />
      </StepWizard>
    </div>
  )
}

export default Signup
