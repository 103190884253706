import React, {useState, useEffect} from 'react'
import {useForm} from 'react-hook-form'

const initialFields = [
  {
    id: 'first_name',
    autocomplete: 'given-name',
    placeholder: 'First Name',
    name: 'service_signup[first_name]',
    registerOpts: {
      required: 'Please provide your first name',
      minLength: {value: 2, message: 'Requires minimum of 2 characters'},
    },
  },
  {
    id: 'last_name',
    autocomplete: 'family-name',
    placeholder: 'Last Name',
    name: 'service_signup[last_name]',
    registerOpts: {
      required: 'Please provide your last name',
      minLength: {value: 2, message: 'Requires minimum of 2 characters'},
    },
  },
  {
    id: 'company_name',
    autocomplete: '',
    placeholder: 'Company Name',
    name: 'service_signup[company_name]',
    registerOpts: {
      required: 'Please provide your company name',
      minLength: {value: 2, message: 'Requires minimum of 2 characters'},
    },
  },
  {
    id: 'email',
    autocomplete: 'email',
    placeholder: 'Email',
    name: 'service_signup[email]',
    registerOpts: {
      required: 'Please provide your email',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
      minLength: {value: 3, message: 'Requires minimum of 3 characters'},
    },
  },
  {
    id: 'phone',
    autocomplete: 'tel tel-national',
    placeholder: 'Phone Number',
    name: 'service_signup[phone]',
    registerOpts: {
      required: 'Please provide your phone number',
      pattern: {
        value: /^[0-9]{10}$/,
        message: 'Phone Number must be 10 digits with only numbers',
      },
    },
  },
  {
    id: 'address_street',
    autocomplete: 'street-address',
    placeholder: 'Street Address',
    name: 'service_signup[address_street]',
    registerOpts: {required: 'Please provide your street address'},
  },
  {
    id: 'address_city',
    autocomplete: 'address-level2',
    placeholder: 'City',
    name: 'service_signup[address_city]',
    registerOpts: {required: 'Please provide your city'},
  },
  {
    id: 'address_zip',
    autocomplete: 'postal-code',
    placeholder: 'Zip',
    name: 'service_signup[address_zip]',
    registerOpts: {
      required: 'Please provide your zip',
      pattern: {
        value: /^\d{5}$/,
        message: 'must be 5 numbers',
      },
    },
  },
  {
    id: 'referred_by',
    autocomplete: '',
    placeholder: 'Referred By',
    name: 'service_signup[referred_by]',
    registerOpts: {
      minLength: {value: 2, message: 'Requires minimum of 2 characters'},
    },
  },
]

const Account = ({name, csrf_token, signup, setSignup, setServerErrors, ...props}) => {
  const uuid = new URLSearchParams(document.location.search).get('uuid')
  const category = new URLSearchParams(document.location.search).get('category')

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm()
  const [submitting, setSubmitting] = useState(null)

  function clearSignup(e) {
    // Key forces a re-render of the form to clear fields
    // see ./signup/Account.js:201
    const key = signup.key || 1
    setSignup({key: key + 1})
    window.location.reload()
  }

  useEffect(() => {
    const fetchExistingSignup = async () => {
      if (submitting) {
        return
      }

      setServerErrors(null)
      setSubmitting(true)
      const response = await fetch(`/service_signup/uuid`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf_token,
        },
        body: JSON.stringify({
          service_signup: {
            entity: name,
            uuid: uuid,
          },
        }),
      })

      const respData = await response.json()
      if (respData !== null) {
        if (respData.data.link) {
          setServerErrors({message: respData.data.message, link: respData.data.link})
        } else if (respData.data) {
          setSignup(respData.data)
          window.location.reload()
        } else {
          setServerErrors({uuid: ['Request failed']})
        }
        setSubmitting(false)
        if (respData.errors) {
          setServerErrors(respData.errors)
        }
      }
    }
    if (uuid !== null && uuid !== signup.uuid) {
      fetchExistingSignup()
    }
  }, [uuid])

  const onSubmit = async formData => {
    if (submitting) {
      return
    }

    setServerErrors(null)
    setSubmitting(true)
    const {address_state, ...filteredSignup} = signup
    let response
    if (signup.uuid) {
      response = await fetch(`/service_signup/${name}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf_token,
        },
        body: JSON.stringify({
          service_signup: {
            entity_name: name,
            ...filteredSignup,
            ...formData.service_signup,
            signup_state: 'account',
          },
        }),
      })
    } else {
      response = await fetch('/service_signup/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf_token,
        },
        body: JSON.stringify({
          service_signup: {...filteredSignup, entity_name: name, ...formData.service_signup, signup_state: 'account'},
        }),
      })
    }

    let respData = await response.json()

    if (respData !== null) {
      setSignup(respData.data)
      setSubmitting(false)

      if (respData.errors) {
        setServerErrors(respData.errors)
      } else {
        props.nextStep()
      }
    }
  }

  return (
    <div className="container-fluid py-3 px-5">
      <div className="row">
        <div className="col text-center">
          <div className="h1 mb-4">Account Details</div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} key={signup.key} className="form-row">
        {initialFields.map(field => {
          return field.id === 'company_name' && category !== 'business' ? null : (
            <div key={field.id} className={`col-md-6 mb-2`}>
              <input
                placeholder={field.placeholder}
                id={field.id}
                name={field.name}
                defaultValue={signup[field.id]}
                autoComplete={field.autocomplete}
                className={errors?.service_signup && errors.service_signup[field.id] ? 'is-invalid form-control' : 'form-control'}
                {...register(field.name, field.registerOpts)}
              ></input>
              {errors?.service_signup && errors?.service_signup[field.id] ? (
                <div className="invalid-feedback">
                  {(errors?.service_signup && errors.service_signup[field.id].message) || 'This field is required'}
                </div>
              ) : null}
            </div>
          )
        })}
        <div className="col-12 text-right">
          <div className="row justify-content-between">
            <div className="order-2">
              <button className="btn btn-primary" disabled={submitting}>
                Submit Details
              </button>
            </div>
            <div className="order-1">
              <button
                onClick={e => {
                  if (confirm('Clear Form?')) {
                    clearSignup(e)
                  }
                }}
                className="btn btn-light mx-2 text-dark"
              >
                Clear Form
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Account
