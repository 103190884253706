import React, { useState, useEffect } from 'react'
import '../stylesheets/Signup.scss'
import StepWizard from 'react-step-wizard'
import Form from './contact_form/Form'
import Complete from './contact_form/Complete'

const ContactForm = (props) => {
  const [serverErrors, setServerErrors] = useState(null)

  return (
    <div className="jarvis-signup p-3">
      {serverErrors ? (
        <div className="alert alert-danger" role="alert">
          {Object.keys(serverErrors).map(error => {
            return (
              <div key={error}>
                {error}: {serverErrors[error][0]}
              </div>
            )
          })}
        </div>
      ) : null}
      <StepWizard isHashEnabled={true} isLazyMount={true}>
        <Form setServerErrors={setServerErrors} {...props} />
        <Complete setServerErrors={setServerErrors} {...props} />
      </StepWizard>
    </div>
  )
}

export default ContactForm
