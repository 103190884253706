import React, { useState, useEffect } from 'react';

const Header = ({ props }) => {
  const { customer, tags, entity_name } = props;

  return (
    <div className="page-header">
      <div className="page-header-content header-elements-lg-inline">
        <div className="page-title d-flex">
          <h4>
            <a href={`/crm/${entity_name}/customers`}>
              <i className="icon-arrow-left52 mr-2"></i>
              <span className="font-weight-semibold">Customers</span>
            </a> - <i className={`icon-${customer?.icon} mr-1 opacity-70`}></i>{customer?.name}&nbsp;
            {tags.map(tag => (
              <span key={tag.id} className="badge badge-secondary" style={{backgroundColor: tag.color}}>{tag.name}</span>
            ))}
          </h4>
          <a href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more"></i></a>
        </div>

        <div className="header-elements d-none text-center text-lg-left mb-3 mb-lg-0">
          <div className="btn-group position-static">
            <a href={`/new_crm_customer_equipment_pickup?customer_id=${customer?.id}`} className="btn btn-indigo">
              <i className="icon-stack2 mr-2"></i> Create Equipment Pickup
            </a>
            <button type="button" className="btn btn-indigo dropdown-toggle" data-toggle="dropdown" aria-expanded="false"></button>
            <div className="dropdown-menu dropdown-menu-right" style={{}}>
              <div className="dropdown-header">Links</div>
              <a href={customer?.crm_link} className="dropdown-item" target="_blank">
                <i className="icon-file-eye"></i> UCRM
              </a>
              <a href="#" className="dropdown-item"><i className="icon-file-stats"></i> UNMS</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;