import React from 'react'

const DeviceConnectionLabel = ({ link }) => {
  
  return (
    <>
      <p>
        RX speed: {link.speed_rx}<br/>
        TX speed: {link.speed_tx}
      </p>
    </>
  )
}

export default DeviceConnectionLabel