import React, {useEffect, useState} from 'react'
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js'

export default function CheckoutForm({csrf_token, signup, setSignup, setServerErrors, validatingPromo, name, ...props}) {
  const [clientSecret, setClientSecret] = useState(null)
  const [error, setError] = useState(null)
  const [succeeded, setSucceeded] = useState(false)
  const [processing, setProcessing] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    const createSetupIntent = async () => {
      const response = await fetch('/service_signup/create_setup_intent/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf_token,
        },
        body: JSON.stringify({
          entity: name,
          service_signup: {
            ...signup,
            signup_state: 'payment'
          },
        })
      })

      const respData = await response.json()

      if (respData !== null) {
        if (respData.errors) {
          setServerErrors(respData.errors)
        } else {
          setClientSecret(respData.data.client_secret)
        }
      }
    }
    createSetupIntent()

  }, [props.isActive])

  const completeForm = async paymentMethodID => {
    if (processing) {
      return
    }

    setServerErrors(null)
    setProcessing(true)
    const response = await fetch(`/service_signup/${name}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        service_signup: {
          ...signup,
          signup_state: 'payment',
        },
        payment_method_id: paymentMethodID
      }),
    })

    let respData = await response.json()
    if (respData !== null) {
      setProcessing(false)
      if (respData.errors) {
        setServerErrors(respData.errors)
      } else {
        props.nextStep()
      }
    }
  }

  const handleSubmit = async ev => {
    ev.preventDefault()
    setProcessing(true)

    // Step 3: Use clientSecret from SetupIntent and the CardElement
    // to confirm payment with stripe.confirmCardPayment()
    const payload = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: ev.target.name.value,
        },
      },
    })

    if (payload.error) {
      setError(`Payment failed: ${payload.error.message}`)
      setProcessing(false)
      // console.log('[error]', payload.error)
    } else {
      setError(null)
      setSucceeded(true)
      setProcessing(false)
      completeForm(payload.setupIntent.payment_method)
    }
  }


  const renderForm = () => {
    const options = {
      style: {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '21px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      },
    }

    return (
      <form onSubmit={handleSubmit}>
        <h4>Confirm Payment</h4>

        <div className="sr-combo-inputs">
          <div className="sr-combo-inputs-row">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Cardholder Name"
              autoComplete="cardholder"
              className="sr-input"
            />
          </div>

          <div className="sr-combo-inputs-row">
            <CardElement
              className="sr-input sr-card-element"
              options={options}
            />
          </div>
        </div>

        {error && <div className="message sr-field-error">{error}</div>}

        <button
          className="btn btn-primary"
          disabled={processing || !clientSecret || !stripe || validatingPromo}
        >
          {processing || !clientSecret || !stripe || validatingPromo ? 'Processing…' : 'Pay'}
        </button>
      </form>
    )
  }

  return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        {renderForm()}
      </div>
    </div>
  )
}
