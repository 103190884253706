import React, {useState, useEffect} from 'react'
import {useForm} from 'react-hook-form'

const initialFields = [
  {
    id: 'name',
    autocomplete: 'given-name',
    placeholder: 'Name',
    name: 'contact_form[name]',
    registerOpts: {
      required: 'Please provide your name',
      minLength: {value: 2, message: 'Requires minimum of 2 characters'},
    },
  },
  {
    id: 'subject',
    autocomplete: '',
    placeholder: 'Subject',
    name: 'contact_form[subject]',
    registerOpts: {
      required: 'Subject',
      minLength: {value: 2, message: 'Requires minimum of 2 characters'},
    },
  },
  {
    id: 'email',
    autocomplete: 'email',
    placeholder: 'Email',
    name: 'contact_form[email]',
    registerOpts: {
      required: 'Please provide your email',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
      minLength: {value: 3, message: 'Requires minimum of 3 characters'},
    },
  },
  {
    id: 'body',
    autocomplete: '',
    placeholder: 'Body',
    type: 'textarea',
    name: 'contact_form[body]',
    registerOpts: {
      required: 'Body',
      minLength: {value: 10, message: 'Requires minimum of 10 characters'},
    },
  },
]

const Account = ({name, csrf_token, setServerErrors, ...props}) => {
  const {register, handleSubmit, formState: { errors }} = useForm()
  const [submitting, setSubmitting] = useState(null)
  const onSubmit = async formData => {
    if (submitting) {
      return
    }

    setServerErrors(null)
    setSubmitting(true)

    const response = await fetch('/contact_form/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        contact_form: {...formData.contact_form, entity: name},
      }),
    })

    let respData = await response.json()

    if (respData !== null) {
      setSubmitting(false)
      if (respData.errors) {
        setServerErrors(respData.errors)
      } else {
        props.nextStep()
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container-fluid py-3 px-5">
        <div className="form-row">
          {initialFields.map(field => {
            return field.type === 'textarea' ? (
              <div key={field.id} className="col-md-12 mb-2">
                <label htmlFor={field.id}>{field.placeholder}</label>
                <textarea
                  id={field.id}
                  name={field.name}
                  autoComplete={field.autocomplete}
                  className={errors?.contact_form && errors.contact_form[field.id] ? 'is-invalid form-control' : 'form-control'}
                  {...register(field.name, field.registerOpts)}
                ></textarea>
                {errors?.contact_form && errors?.contact_form[field.id] ? (
                  <div className="invalid-feedback">
                    {(errors?.contact_form && errors.contact_form[field.id].message) || 'This field is required'}
                  </div>
                ) : null}
              </div>
            ) : (
              <div key={field.id} className="col-md-12 mb-2">
                <label htmlFor={field.id}>{field.placeholder}</label>
                <input
                  id={field.id}
                  name={field.name}
                  autoComplete={field.autocomplete}
                  className={errors?.contact_form && errors.contact_form[field.id] ? 'is-invalid form-control' : 'form-control'}
                  {...register(field.name, field.registerOpts)}
                ></input>
                {errors?.contact_form && errors?.contact_form[field.id] ? (
                  <div className="invalid-feedback">
                    {(errors?.contact_form && errors.contact_form[field.id].message) || 'This field is required'}
                  </div>
                ) : null}
              </div>
            )
          })}
          <div className="col-12 text-right">
            <button className="btn btn-primary" disabled={submitting}>
              Submit Details
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Account
