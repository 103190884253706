import React, {useState, ReactDOM} from 'react'
import Header from './Header'
import ActivityFeed from './ActivityFeed'

const Show = ({csrf_token, ...props}) => {
  const {customer} = props;
  return (
    <div className="content pt-8">
      <Header props={props} />
      <div className="row">
        <div className="col-lg-7">
          <div className="card">
            <div className="card-header header-elements-inline">
              <h5 className="card-title"><b>JARVIS</b> Customer Info</h5>
            </div>

            <div className="collapse show">
              <div className="card-body">
                <form action="#">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">First Name</label>
                    <div className="col-lg-9">
                      <input type="text" defaultValue={customer.first_name} className="form-control" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">Last Name</label>
                    <div className="col-lg-9">
                      <input type="text" defaultValue={customer.last_name} className="form-control" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">Email</label>
                    <div className="col-lg-9">
                      <input type="text" defaultValue={customer.email} className="form-control" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">Phone</label>
                    <div className="col-lg-9">
                      <input type="text" defaultValue={customer.phone} className="form-control" />
                    </div>
                  </div>
                  <div className="text-right">
                    <button type="submit" className="btn btn-primary">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5">
          <ActivityFeed event_types={props.event_types} events={props.events} />
        </div>
      </div>
    </div>
  )
}

export default Show;