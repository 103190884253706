import React from 'react'

const SignupMapLabel = ({ signup }) => {
  return (
    <>
      <h6><b>{signup.name}</b></h6>
      <p>{signup.address}</p>
      <p>Plan: {signup?.plan_name}</p>
    </>
  )
}

export default SignupMapLabel