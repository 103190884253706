import React, { useEffect } from 'react'

const Complete = ({ bookable_appointment }) => {
  return (
    <div className="container-fluid px-5">
      <div className="row mb-4">
        <div className="col">
          {bookable_appointment.completed_step ? (
            <div dangerouslySetInnerHTML={{__html: bookable_appointment.completed_step}} />
          ) : (
            <div className='text-center h3'>Appointment Scheduled.</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Complete