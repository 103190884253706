import React, {useState, useEffect} from 'react'

const Terms = ({name, terms, commercial_terms, csrf_token, signup, setSignup, setServerErrors, ...props}) => {
  const category = new URLSearchParams(document.location.search).get('category')
  const [submitting, setSubmitting] = useState(null)

  useEffect(() => {
    if (!signup.uuid) props.goToStep(1)
  }, [])

  const acceptTerms = async () => {
    const currentTime = new Date()
    if (submitting) {
      return
    }

    setServerErrors(null)
    setSubmitting(true)
    const response = await fetch(`/service_signup/${name}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        service_signup: {
          ...signup,
          signup_state: 'terms',
          terms_accepted_on: currentTime,
        },
      }),
    })

    let respData = await response.json()
    if (respData !== null) {
      setSignup(respData.data)
      setSubmitting(false)
      if (respData.errors) {
        setServerErrors(respData.errors)
      } else {
        props.nextStep()
      }
    }
  }

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col">
          <div dangerouslySetInnerHTML={{__html: category === 'business' ? commercial_terms : terms}} />
          {signup.uuid ? (
            <button
              className="btn btn-primary"
              onClick={() => acceptTerms()}
              disabled={submitting}
            >
              Accept Terms and Continue
            </button>
          ) : (
            <button className="btn btn-primary" onClick={() => props.goToStep(1)}>
              Missing details, please return to account step.
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
export default Terms
