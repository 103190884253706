import React, { useState, useEffect } from 'react'
import Nav from './cs/Nav'
import '../stylesheets/Signup.scss'
import StepWizard from 'react-step-wizard'
import Account from './signup/Account'
import Terms from './signup/Terms'
import Schedule from './cs/Schedule'
import Plans from './signup/Plans'
import Confirm from './cs/Confirm'
import Complete from './signup/Complete'

const Signup = (props) => {
  const [signup, setSignup] = useState(JSON?.parse(localStorage.getItem('currentSignup')) || {})
  const [serverErrors, setServerErrors] = useState(null)
  const todaysDate = new Date()

  useEffect(() => {
    if (signup) {
      let currentSignup = JSON.stringify(signup)
      localStorage.setItem('currentSignup', currentSignup)
    }
  }, [signup])

  return (
    <div className="jarvis-signup p-3">
      {serverErrors ? (
        <div className="alert alert-danger" role="alert">
          {Object.keys(serverErrors).map(error => {
            return (
              <div key={error}>
                {error}: {serverErrors[error][0]}
              </div>
            )
          })}
        </div>
      ) : null}
      <StepWizard isHashEnabled={true} nav={<Nav />} isLazyMount={true}>
        <Account signup={signup} setSignup={setSignup} setServerErrors={setServerErrors} {...props} />
        <Terms signup={signup} setSignup={setSignup} setServerErrors={setServerErrors} {...props} />
        <Schedule signup={signup} setSignup={setSignup} setServerErrors={setServerErrors} todaysDate={todaysDate} {...props} />
        <Plans signup={signup} setSignup={setSignup} setServerErrors={setServerErrors} {...props} />
        <Confirm signup={signup} setSignup={setSignup} setServerErrors={setServerErrors} {...props} />
        <Complete signup={signup} setSignup={setSignup} setServerErrors={setServerErrors} {...props} />
      </StepWizard>
    </div>
  )
}

export default Signup
