import React, {useState, ReactDOM} from 'react'
import GoogleMapReact from 'google-map-react'
import Select from 'react-select'
import SignupMapLabel from './SignupMapLabel'
import { renderToString } from 'react-dom/server'
import DeviceMapLabel from './DeviceMapLabel'
import DeviceConnectionLabel from './DeviceConnectionLabel'

let currentOpenInfoWindow = null

const DeviceMap = ({apiKey, csrf_token, devices}) => {
  const [mapState, setMapState] = useState({
    lat: 40.523512,
    lng: -104.8512,
    zoom: 12,
    editMode: false,
    devices: devices,
  })

  const deviceLinks = (device, serial) => {
    let links = device.data?.radio_common?.links?.active || []
    if (serial) {
      return links.find(link => link.remote_assigned_name === serial)
    }

    return links
  }

  function setEditMode(value) {
    const zoomValue = alternateZoom(10)
    setMapState({...mapState, editMode: value, zoom: zoomValue})
  }

  function alternateZoom(val) {
    return mapState.zoom === val ? val - 1 : val
  }

  // Save subscriber device location ondrag end of location
  async function saveDeviceLocation(device) {
    const response = await fetch('/geolocations/subscriber_device_location', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        subscriber_device: device,
      }),
    })

    let respData = await response.json()
    console.log(respData)
  }

  // Initialize map and load relavent information
  const handleApiLoaded = (map, maps, devices) => {
    let devicesWithSerial = {}
    devices.map(d => (devicesWithSerial[d.serial] = [{ lat: d.lat, lng: d.lng}]))

    console.log(devicesWithSerial)

    // Loop devices and create circle based on range and device color
    devices.forEach(device => {
      const latLng = new maps.LatLng(device.lat, device.lng)

      const circle = new maps.Circle({
        map: map,
        radius: device.range,
        draggable: mapState.editMode,
        editable: mapState.editMode,
        fillColor: device.color ? device.color : '#DDDDFF',
        fillOpacity: 0.2,
        strokeColor: device.color ? device.color : '#DDDDFF',
        strokeOpacity: 0.8,
        center: latLng,
      })

      deviceLinks(device).forEach(link => {
        let linkSerial = link.remote_assigned_name
        let coords = devicesWithSerial[linkSerial]

        if (coords) {
          coords.push({lat: device.lat, lng: device.lng })
          let line = new google.maps.Polyline({
            path: coords,
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 4,
            map: map,
            zIndex: 9999
          })

          line.addListener('click', () => {
            console.log(device, deviceLinks(device, linkSerial))
          })

          line.addListener('mouseover', (event) => {
            let infoWindow = new maps.InfoWindow({
              open: true,
              content: "",
              position: event.latLng,
              map,
              shouldFocus: false
            })
    
            const content = renderToString(<DeviceConnectionLabel link={deviceLinks(device, linkSerial)} />)
            infoWindow.setContent(content)
            currentOpenInfoWindow?.close()
            currentOpenInfoWindow = infoWindow
          })

          line.addListener('mouseout', () => {
            currentOpenInfoWindow?.close()
          })
        }
      })

      // Only save location changes in edit mode
      if (mapState.editMode) {
        circle['signupMetadata'] = device
        circle.addListener('dragend', function (currentMarker) {
          const _this = this
          const lat = currentMarker.latLng.lat()
          const lng = currentMarker.latLng.lng()
          saveDeviceLocation({latitude: lat, longitude: lng, id: _this.signupMetadata.id})
        })

        map.addListener('click', event => {
          new maps.Circle({
            map: map,
            draggable: true,
            editable: true,
            radius: 182,
            fillColor: '#DDDDFF',
            fillOpacity: 0.2,
            strokeColor: '#DDDDFF',
            strokeOpacity: 0.8,
            center: event.latLng,
          })
        })
      }

      // Show Subscriber Device information on click
      circle.addListener('click', () => {
        let infoWindow = new maps.InfoWindow({
          open: true,
          content: "",
          position: latLng,
          map,
          shouldFocus: false,
        })

        const content = renderToString(<DeviceMapLabel device={device} />)
        infoWindow.setContent(content)
        currentOpenInfoWindow?.close()
        currentOpenInfoWindow = infoWindow
      })
    })
  }

  function handleSelection(event) {
    setMapState({...mapState, lat: event.device.lat, lng: event.device.lng, zoom: 17})
  }

  return (
    <div className="container-fluid">
      <div className="row py-2">
        <div className="col-auto">
          <label>Subscriber Device</label>
          <Select
            placeholder="Select Subscriber Device..."
            options={devices.map(device => {
              return {value: device.id, label: `${device.name} - ${device.ip_address ? device.ip_address : 'No IP'}`, device: device}
            })}
            onChange={handleSelection}
          />
        </div>
        <div className="col-auto">
          {mapState.editMode ? (
            <button onClick={() => setEditMode(false)} className="btn btn-primary my-3">
              Exit Edit Mode
            </button>
          ) : (
            <button onClick={() => setEditMode(true)} className="btn btn-success my-3">
              Switch to Edit Mode
            </button>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 jarvis-map p-3 border" style={{height: '90vh'}}>
          <GoogleMapReact
            key={mapState.zoom} // Changing the key value forces re-render
            // bootstrapURLKeys={{ key: apiKey, libraries:['places', 'geometry', 'drawing', 'visualization'] }}
            bootstrapURLKeys={{key: apiKey, libraries: ['places', 'geometry', 'visualization']}}
            center={{lat: mapState.lat, lng: mapState.lng}}
            zoom={mapState.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps, devices)}
          />
        </div>
      </div>
    </div>
  )
}

export default DeviceMap
