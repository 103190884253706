import React, {useState, ReactDOM} from 'react'
import GoogleMapReact from 'google-map-react'
import Select from 'react-select'
import SignupMapLabel from './SignupMapLabel'
import {renderToString} from 'react-dom/server'
import downloadCsv from './DownloadCsv'

let currentOpenInfoWindow = null

function SignupMap({apiKey, csrf_token, signups}) {
  const [mapState, setMapState] = useState({
    lat: 40.523512,
    lng: -104.8512,
    zoom: 12,
    editMode: false,
    filteredSignups: signups,
    selectedSignups: [],
  })
  const [currentViewSignupCount, setCurrentViewSignupCount] = useState(signups.length)

  const stateMap = signupState => ({complete: 'green-dot'}[signupState] || 'red-dot')
  function exportSignups(signups) {
    let headers = ['id,email,first_name,last_name,address']

    // Convert users data to a csv
    let signupsCsv = signups.reduce((acc, signup) => {
      const {id, email, first_name, last_name, address} = signup
      acc.push([id, email, first_name, last_name, address].join(','))
      return acc
    }, [])

    downloadCsv({
      data: [...headers, ...signupsCsv].join('\n'),
      fileName: 'signups.csv',
      fileType: 'text/csv',
    })
  }
  // Save signup location ondrag end of location
  async function saveSignupLocation(signup) {
    const response = await fetch('/geolocations/signup_location', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        signup: signup,
      }),
    })

    let respData = await response.json()
    console.log(respData)
  }

  // Initialize map and load relavent information
  const handleApiLoaded = (map, maps, signups) => {
    // Loop Signups and create markers with events for click and dragend
    signups.forEach(signup => {
      const latLng = new maps.LatLng(signup.lat, signup.lng)

      const marker = new maps.Marker({
        position: latLng,
        map: map,
        draggable: mapState.editMode,
        icon: {
          url: `${location.protocol}//maps.google.com/mapfiles/ms/icons/${stateMap(signup.signup_state)}.png`,
        },
      })
      // Only save location changes in edit mode
      if (mapState.editMode) {
        marker['signupMetadata'] = signup
        marker.addListener('dragend', function (currentMarker) {
          const _this = this
          const lat = currentMarker.latLng.lat()
          const lng = currentMarker.latLng.lng()
          saveSignupLocation({latitude: lat, longitude: lng, id: _this.signupMetadata.id})
        })
      }
      // Show signup information on marker click
      marker.addListener('click', () => {
        let infoWindow = new maps.InfoWindow({
          open: true,
          content: '',
          anchor: marker,
          map,
          shouldFocus: false,
        })

        const content = renderToString(<SignupMapLabel signup={signup} />)
        infoWindow.setContent(content)
        currentOpenInfoWindow?.close()
        currentOpenInfoWindow = infoWindow
      })
    })
    var polygon
    var polygonMarkers = []
    var polygonLocations = []
    maps.event.addListener(map, 'click', function (e) {
      var position = e.latLng
      polygonLocations.push(position)
      polygonMarkers.push(
        new maps.Marker({
          icon: `${location.protocol}//maps.google.com/mapfiles/kml/paddle/ylw-circle-lv.png`,
          position: position,
          map: map,
        }),
      )
      drawPolygon(polygonLocations)
    })

    function drawPolygon(points) {
      if (points.length < 3) {
        return
      }

      if (polygon) {
        polygon.setMap(null)
      }
      polygon = new maps.Polygon({
        paths: points,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map: map,
      })

      polygon.addListener('click', () => {
        clearSelection()
      })
      setSelectedSignups(polygon)
    }
    function setSelectedSignups(polygon) {
      const selected = []
      signups.forEach(signup => {
        if (maps.geometry.poly.containsLocation(new maps.LatLng(signup.lat, signup.lng), polygon)) {
          selected.push(signup)
        }
      })
      setMapState({...mapState, selectedSignups: selected})
    }
    function clearSelection() {
      if (polygon) {
        polygon.setMap(null)
      }
      for (var i in polygonMarkers) {
        polygonMarkers[i].setMap(null)
      }
      polygonLocations = []
      setMapState({...mapState, selectedSignups: []})
    }
  }

  function handleSelection(event) {
    setMapState({...mapState, lat: event.signup.lat, lng: event.signup.lng, zoom: 17})
  }

  const onMapChange = ({bounds}) => {
    let {ne, nw, se, sw} = bounds

    if (signups.length) {
      const foundSignups = signups.filter(signup => {
        if (signup.lat > se.lat && sw.lat && signup.lat < ne.lat && nw.lat && signup.lng > nw.lng && sw.lng && signup.lng < ne.lng && se.lng) {
          return signup
        }
      })

      setCurrentViewSignupCount(foundSignups.length)
    }
  }

  return (
    <div className="container-fluid">
      <div className="row py-2">
        <div className="col-auto">
          <label>Signup</label>
          <Select
            placeholder="Select Signup..."
            options={signups.map(signup => {
              return {value: signup.id, label: signup.name, signup: signup}
            })}
            onChange={handleSelection}
          />
        </div>
        <div className="colAuto">
          <p>Signups in current view: {currentViewSignupCount}. </p>
          <div>
            {mapState.selectedSignups.length > 0 ? (
              <button onClick={() => exportSignups(mapState.selectedSignups)} className="btn btn-primary">
                Export {mapState.selectedSignups.length} Selected Signups
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 jarvis-map p-3 border" style={{height: '90vh'}}>
          <GoogleMapReact
            key={mapState.zoom} // Changing the key value forces re-render
            // bootstrapURLKeys={{ key: apiKey, libraries:['places', 'geometry', 'drawing', 'visualization'] }}
            bootstrapURLKeys={{key: apiKey, libraries: ['places', 'geometry', 'visualization']}}
            center={{lat: mapState.lat, lng: mapState.lng}}
            zoom={mapState.zoom}
            yesIWantToUseGoogleMapApiInternals
            onChange={onMapChange}
            onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps, mapState.filteredSignups)}
          />
        </div>
      </div>
    </div>
  )
}

export default SignupMap
