// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
import "activeadmin_addons"

import tinymce from 'tinymce/tinymce';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/icons/default/icons';
import 'tinymce/themes/silver/theme';
import 'tinymce/plugins/link';
import 'tinymce/plugins/code';
import 'tinymce/plugins/lists';

const deviceSelectInit = function(e) {
  let $element = e ? $(e.target).closest('fieldset').find('select.device-select') : $('.device-select');
  $element.select2({
    width: '40%',
    // data is dependent on the value of pickup_location and customer_id
    ajax: {
      url: '/admin/equipment_pickups/devices',
      dataType: 'json',
      delay: 250,
      data: function(params) {
        return {
          customer_id: $('#equipment_pickup_customer_id').val(),
          location_id: $('#equipment_pickup_pickup_location_id').val()
        };
      },
      processResults: function(data, params) {
        return {
          results: data.map((device) => {
            return {
              id: device.id,
              text: `${device.model} - ${device.pickup_uid} (${device.sticker_color})`
            };
          })
        };
      },
      cache: true
    },
  });
}

const rowSelectInit = function(event) {
  $('.ticket-kind-select').on('change', function(e) {
    if ($(this).val() === 'pickup') {
      deviceSelectInit(e);
    } else {
      // $('.ticket-kind-other').hide();
    }
  })
}

const aaSelectInit = function() {
  $('select:not([class*="select2-hidden-accessible"])').select2();
  deviceSelectInit();
  rowSelectInit();

  $(document).on("has_many_add:after", (e) => {
    $('select:not([class*="select2-hidden-accessible"])').select2();
    rowSelectInit(e);
  })
}


const onDReady = function() {
  tinymce.init({
    theme: 'silver',
    skin: false,
    menubar: false,
    toolbar: 'styleselect | bold italic underline link unlink openlink | undo redo | blockquote numlist bullist | code',
    selector: 'textarea.tinymce',
    plugins: ['link', 'lists', 'code'],
    paste_auto_cleanup_on_paste : true,
    paste_postprocess: (pl, o) => {
      o.node.innerHTML = o.node.innerHTML.replace(/<p.*>\s*(<br>|&nbsp;)\s*<\/p>/ig, "")
    }
  });
  aaSelectInit();
}

$(onDReady).on('page:load turbolinks:load', onDReady);

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);